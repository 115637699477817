import React from 'react';
import {Link} from 'react-router-dom'
import lessonImg from '../images/lesson-image.png';
import playgroundImg from '../images/playground-image.png';
import goal from '../images/goal.png';
import portfolioImg from '../images/portfolio-image.png';
import assessmentImg from '../images/assessment.png';
import $ from 'jquery/dist/jquery.js';



   const dashboardBody = (props) => { 


    return(
   <div className="container standard-padding-tb "> 
          <div className="row text-center blue-text"> 
          <div className="col-12 col-md-7 mx-auto blue-background-light standard-round-box-curve-2 navbarStyle" id=""> 
              <div> 
              <img class="img-fluid" src={lessonImg} width="400" />
              </div>
            
              <Link to="/lab/html-and-the-web/lessons/class-plan-web">
              <button className="btn btn-success standard-round-box-curve white-background blue-text header-font tabs-btn-hover">  {props.candidateFirstName} ,Start Coding Class </button>
              </Link>
         </div>
{/*   
        <div className="col-12 col-md-7">
        <div className="row text-center"> 
  <div className="col-12 col-md-4 offset-md-2 blue-background-light standard-round-box-curve-2 standard-padding-top standard-margin-top navbarStyle" id=""> 
       <img class="img-fluid" src={playgroundImg} width="100" />

       <Link to="/playground/start/select" > 
     <button className="btn btn-success standard-round-box-curve white-background blue-text header-font tabs-btn-hover">   code playground </button>
     </Link>
   
         </div>

         <div className="col-12 col-md-4 offset-md-2 blue-background-light standard-round-box-curve-2 standard-padding-top standard-margin-top navbarStyle" id=""> 
<img class="img-fluid" src={goal} width="100" />
          
            <Link to="/dashboard/progress">
           <button className="btn btn-success standard-round-box-curve white-background blue-text header-font tabs-btn-hover"> {props.candidateFirstName}'s Syllabus </button>
           </Link> 
          
         </div>

         <div className="col-12 col-md-4 offset-md-2 standard-round-box-curve-2 standard-padding-top standard-margin-top navbarStyle" id=""> 
 <img class="img-fluid" src={portfolioImg} width="100" />
          
                <Link to="/portfolio/home">
                <button className="btn btn-success standard-round-box-curve white-background blue-text header-font tabs-btn-hover">   Portfolio by {props.candidateFirstName} </button>
                </Link> 
        
         </div>

         <div className="col-12 col-md-4 offset-md-2 standard-round-box-curve-2 standard-padding-top standard-margin-top navbarStyle" id="assessments"> 
<img class="img-fluid" src={assessmentImg} width="100" />
               <Link to={assessmentLink}>
               <button className="btn btn-success standard-round-box-curve white-background blue-text header-font tabs-btn-hover">  Class assessment </button>
               </Link>
         </div> 
         
        </div> 

        </div>

*/}
        </div>

        </div> 
   
      )


   } 

   export default dashboardBody