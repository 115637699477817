import React, {Component} from 'react';
import {Route, Switch } from 'react-router-dom';
import {history} from 'history';
import { CookiesProvider } from 'react-cookie';
import '../App.css';
import NavDashboard from './nav-dashboard';
import {Modal} from 'react-bootstrap';
import DashboardBody from './dashboard-body'
import Playground from './playground';
import Assessment from './assessments';
import Progress from './progress';
import TheWeb from './learningmodules/theweb';
import AwesomeSlider from 'react-awesome-slider';
import str from '../images/ngr-strong.png'
import explore from '../images/explore.png'

class Dashboard extends Component { 

constructor(props){ 
  super(props);
  this.state= { 
      isLoading:"true",
      email: "",
      candidateFirstName: "",
      progressStatus:"",
      candidateCurrentTopic: "",
      candidateCurrentResource: "",
      candidateSkin: "", 
      classAssessmentStatus:"",
      currentAssessmentTaskRef:"",
      currentAssessmentTopicRef:"",
      currentAssessmenRef:"",
      classYear:"",
      schoolid:"",
      addManualModalShow:"",
      submissionStatus:""
   }

      this.tokenVerification = this.tokenVerification.bind(this)
       this.retrieveCandidateDetails = this.retrieveCandidateDetails.bind(this);
       this.getAssessmentReferences = this.getAssessmentReferences.bind(this);
 }
   
  

// function call for retreiving data from end point 


async componentDidMount(){ 


 //  this.setState({

   // addManualModalShow:true
   //})
 
  
   await this.tokenVerification()
   await this.retrieveCandidateDetails()

      console.log(this.state.classAssessmentStatus)
   if(this.state.classAssessmentStatus === true){
    console.log('assessment ran')
    this.getAssessmentReferences()
   }

      
}

async componentDidUpdate(){
  console.log(this.state)
}


async tokenVerification(){ 



    const tokenCheck = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/tokenCheck',{ 

      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"
    
    })
    .then(response => response.json())
    .then(response => {
      console.log(response)
        if(!response){
          window.location.href = "/"
        }

  })

}


 async getAssessmentReferences() {

          const classAssessmentFilterParameters = { 
               classYear: this.state.classYear,
               schoolid: this.state.schoolid
          }
            
            console.log(classAssessmentFilterParameters)
    

    const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins/assessmentRef',{ 

      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     body: JSON.stringify(classAssessmentFilterParameters),
     mode:"cors",
     credentials:"include"
    
    })
          .then(response => response.json())
          .then(response => { 
              console.log(response)
              this.setState({  
               currentAssessmentTaskRef: response[0].taskRef,
               currentAssessmentTopicRef: response[0].topicRef,
               currentAssessmentRef: response[0].assessmentRef
              })
          })
    }


async retrieveCandidateDetails(){ 


    const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/demo/candidate',{ 

      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"
    
    })
    .then(response => response.json())
    .then(response => {
          console.log(response)
           this.setState({
            candidateFirstName:response.firstname,
            schoolid : response.schoolid,
           })
 
  })

}



render() { 

  	return (
      <div className="nav-clear-space">
      <header>
          <NavDashboard />
      </header> 
      
     
     
       {this.props.children}

       <Switch>
       <Route path={`/dashboard/home`} render={ (props) => <DashboardBody {...props} candidateFirstName={this.state.candidateFirstName} progressStatus={this.state.progressStatus} currentTopic={this.state.candidateCurrentTopic} currentResource={this.state.candidateCurrentResource} classAssessmentStatus={this.state.classAssessmentStatus} currentAssessmentTaskRef={this.state.currentAssessmentTaskRef} currentAssessmentTopicRef={this.state.currentAssessmentTopicRef} currentAssessmentRef={this.state.currentAssessmentRef} /> } /> />
      
       <Route path={`/dashboard/playground`} component={Playground}   />
       <Route path={`/dashboard/progress`} render={ (props) => <Progress {...props} candidateFirstName={this.state.candidateFirstName} /> } /> 
       <Route path={`/dashboard/assessment`} component={Assessment} />   
       </Switch>


       <div id="MyLiveChatContainer"></div>


          <Modal className="white-background-trans" animation={false}>
           <Modal.Header>
          <Modal.Title className="accent-font"> Happy Democracy Day Coder! </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
         <h6 className="body-font"> To celebrate Democracy day we have provided you with skins for Nigeria and some lessons on Nigeria's 
         democratic history. Use the Explore button to read about Nigeria's Democracy

         </h6>
        
         <AwesomeSlider>

          
           <div data-src={str} />
          
        
           <div data-src={explore} />

         </AwesomeSlider>
      

          </Modal.Body>
        <Modal.Footer className="standard-padding-tb standard-margin-top">

          <button id="" onClick={()=> this.setState({addManualModalShow:false})} className="btn btn-success white-text"> Close </button>
       
        </Modal.Footer>
      </Modal>

   </div>
        
  	)
  }
}




export default Dashboard